<template>
  <div class="create-content-main">
    <div class="create-form">
      <div class="p-4">
        <div class="mb-2 d-flex justify-content-between">
          <h2 class="w-75">カバー登録 / 編集</h2>
          <div
            class="col-md-8 col-12 px-0 group-btn-edit-content"
            style="text-align: end"
          >
            <b-button
              variant="primary"
              class="mr-2 btn-set"
              v-on:click="returnEditContent()"
            >
              コンテンツ基本設定
            </b-button>
            <b-button
              variant="primary"
              class="mr-2 btn-set"
              v-on:click="returnSettingSale()"
            >
              ストア設定
            </b-button>
            <button v-on:click="gotoDashboard()" class="button-back-dashboard">
              戻る
            </button>
          </div>
        </div>
        <!-- <div
          class="col-sm-12 mb-4"
          style="
            text-align: center;
            background-color: #3385ff;
            color: white;
            padding: 5px;
            font-size: 30px;
          "
        >

        </div> -->
        <div class="justify-content-center text-center">
          <p class="group-title">基本設定</p>
        </div>
        <draggable
          v-model="dataTableContent"
          group="people"
          @start="drag = true"
          @end="drag = false"
        >
          <div
            v-for="(item, ind) in dataTableContent"
            :key="ind"
            class="objectItem"
          >
            <div v-if="item.page.length > 0">
              <div
                class="card table-of-contentedit"
                header="目次"
                header-tag="header"
                v-if="item.is_page === 0"
              >
                <div class="card-header d-flex flex-nowrap">
                  <b-form-select
                    type="text"
                    class="form-control"
                    v-model="item.catalogue_id"
                    ref="selesct"
                    @change="disableOptionTitlepage(item.catalogue_id, ind)"
                    ><option
                      v-for="ele in dataOptionTitlePage"
                      :value="ele.value"
                      :key="ele.value"
                      :disabled="opDisable.includes(ele.value)"
                    >
                      {{ ele.text }}
                    </option></b-form-select
                  >
                  <b-button
                    class=""
                    href="#"
                    variant="primary"
                    style="
                      margin-left: 1%;
                      background-color: rgb(51, 133, 255);
                      border-color: rgb(51, 133, 255);
                    "
                    v-on:click="showSlagConditionDetail(item, ind)"
                  >
                    <span class="" style="white-space: nowrap">表示設定</span>
                  </b-button>
                  <b-button
                    class="custom-button"
                    href="#"
                    variant="primary"
                    v-on:click="deleteContent(ind, item, item.id)"
                  >
                    <span class="custom-span">-</span>
                  </b-button>
                </div>
                <div class="card-body boder-body">
                  <draggable
                    v-model="item.page"
                    group="people1"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <div
                      v-for="(ele, index) in item.page"
                      :key="index"
                      class="d-flex flex-direction-column align-items-center mb-4"
                    >
                      <div class="border-titlePage" style="max-width: 1100px">
                        {{ ele.page_title }}
                      </div>
                      <b-button
                        class=""
                        href="#"
                        variant="primary"
                        style="
                          margin-left: 1%;
                          background-color: rgb(51, 133, 255);
                          border-color: rgb(51, 133, 255);
                        "
                        v-on:click="showSlagConditionDetailPage(ind, index)"
                      >
                        <span class="" style="white-space: nowrap"
                          >表示設定</span
                        >
                      </b-button>
                      <b-button
                        class="custom-button"
                        href="#"
                        variant="primary"
                        v-on:click="deletePage(index, ele, ind)"
                      >
                        <span class="custom-span">-</span>
                      </b-button>
                    </div>
                  </draggable>
                  <div>
                    <b-button
                      class="custom-button custom-button-plus"
                      href="#"
                      variant="primary"
                    >
                      <b-icon
                        icon="plus"
                        aria-hidden="true"
                        style="font-size: 60px"
                        @click="OpenModalPage(item, ind)"
                      ></b-icon>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item.page.length > 0">
              <div
                class="card customListPage"
                header="目次"
                header-tag="header"
                style="border: none !important; background-color: white"
                v-if="item.is_page === 1"
              >
                <div class="card-body boder-body">
                  <draggable
                    v-model="item.page"
                    group="people1"
                    @start="drag(event)"
                    @end="drag = false"
                  >
                    <div
                      v-for="(ele, index) in item.page"
                      :key="index"
                      class="d-flex flex-direction-column align-items-center mb-4"
                    >
                      <div class="border-titlePage" style="width: 170px">
                        {{ ele.page_title }}
                      </div>
                      <b-button
                        class=""
                        href="#"
                        variant="primary"
                        style="
                          margin-left: 1%;
                          background-color: rgb(51, 133, 255);
                          border-color: rgb(51, 133, 255);
                        "
                        v-on:click="showSlagConditionDetailPage(ind, index)"
                      >
                        <span class="" style="white-space: nowrap"
                          >表示設定</span
                        >
                      </b-button>
                      <b-button
                        class="custom-button"
                        href="#"
                        variant="primary"
                        v-on:click="deletePage(index, ele, ind)"
                      >
                        <span class="custom-span">-</span>
                      </b-button>
                    </div>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </draggable>
        <div class="custom-button-add" style="">
          <b-button
            class="custom-button custom-button-plus"
            href="#"
            variant="primary"
            style=""
          >
            <b-icon
              icon="plus"
              aria-hidden="true"
              style="font-size: 60px"
              @click="AddNewChapter()"
            ></b-icon>
          </b-button>
        </div>
        <div class="custom-button-update">
          <b-button
            type="button"
            class="btn btn-primary button-update"
            v-on:click="updateTableOfContent()"
            :disabled="isLoading"
          >
            <span v-show="!isLoading">更新する</span>
            <div
              v-show="isLoading"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <span class="sr-only">ローディング...</span>
            </div>
          </b-button>
        </div>
        <div>
          <b-modal
            id="modal-show-page"
            modal-class="modal-show-page"
            ref="modal-show-page"
            :hide-footer="isShowFooter"
          >
            <template #modal-header="{}">
              <b-button
                v-if="showAddPage === 1 || showAddPage === 2"
                size="sm"
                style="
                  background-color: white;
                  border-color: white;
                  font-size: 15px;
                "
                class=""
                @click="backMenuAdd()"
              >
                <i class="fas fa-arrow-left"></i>
              </b-button>
              <b-button
                size="sm"
                style="background-color: white; border-color: white"
                class="close"
                @click="closeModal()"
              >
                <i class="fas fa-times"></i>
              </b-button>
            </template>
            <div class="page-list-content p-0">
              <div class="row" v-if="selectAdd === 1">
                <div class="col-6" @click="addPage()">
                  <div class="customSelectAdd text-center">ページ追加</div>
                </div>
                <div class="col-6" @click="addChapter()">
                  <div class="customSelectAdd text-center">章追加</div>
                </div>
              </div>
              <div v-else>
                <div v-if="showAddPage === 1">
                  <div
                    class=""
                    v-for="(item, index) in dataListPage"
                    :key="index"
                  >
                    <label
                      class="custom-label d-flex justify-content-between"
                      v-bind:class="{ select: index === isChecked }"
                      v-on:click="ClickAddPage(item, index)"
                      >{{ item.page_title }}
                      <b-icon
                        v-if="item._checked === true"
                        icon="check"
                        scale="2"
                        style="color: #46c000"
                      />
                    </label>
                  </div>
                </div>
                <div v-if="showAddPage === 2">
                  <div
                    class=""
                    v-for="(item, index) in dataListPage"
                    :key="index"
                  >
                    <label
                      class="custom-label d-flex justify-content-between"
                      v-bind:class="{ select: index === isChecked }"
                      v-on:click="ClickPageChecked(item, index)"
                      >{{ item.page_title }}
                      <b-icon
                        v-if="item._checked === true"
                        icon="check"
                        scale="2"
                        style="color: #46c000"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <template
              #modal-footer="{}"
              v-if="showAddPage === 1 || showAddPage === 2"
            >
              <div class="">
                <b-button
                  v-on:click.prevent="updateConditionChapter()"
                  :disabled="isLoading"
                  variant="info"
                  class="mr-4"
                  >アップデート
                </b-button>
                <b-button
                  variant="secondary"
                  @click="closeModal()"
                  class="mx-2 back-btn"
                  >戻る</b-button
                >
              </div>
            </template>
          </b-modal>
        </div>
        <div>
          <b-modal
            id="modal-add-page"
            modal-class="modal-add-page"
            ref="modal-add-page"
          >
            <template #modal-header="{}">
              <b-button
                size="sm"
                style="background-color: white; border-color: white"
                class="close"
                @click="closeModalAddPage()"
              >
                <i class="fas fa-times"></i>
              </b-button>
            </template>
            <div class="page-list-content p-0">
              <div class="" v-for="(item, index) in dataListPage" :key="index">
                <div
                  class="custom-label d-flex justify-content-between align-items-center"
                  v-bind:class="{ select: index === isSelected }"
                  v-on:click="ClickPageAddChecked(item, index)"
                >
                  <div class="textPage">{{ item.page_title }}</div>
                  <b-icon
                    v-if="item._selected === true"
                    icon="check"
                    scale="2"
                    style="color: #46c000"
                  />
                </div>
              </div>
            </div>
            <template #modal-footer="{}">
              <div class="">
                <b-button
                  v-on:click.prevent="addPageInChapter()"
                  :disabled="isLoading"
                  variant="info"
                  class="mr-4"
                  >アップデート
                </b-button>
                <b-button
                  variant="secondary"
                  @click="closeModalAddPage()"
                  class="mx-2 back-btn"
                  >戻る</b-button
                >
              </div>
            </template>
          </b-modal>
        </div>
        <!-- <div class="justify-content-center text-center mt-3">
          <p class="group-title">詳細設定</p>
        </div> -->
        <!-- <div
          class="col-sm-12 mb-4 mt-4"
          style="
            text-align: center;
            background-color: #3385ff;
            color: white;
            padding: 5px;
            font-size: 30px;
          "
        >

        </div> -->
        <!-- <div class="row mb-3 row-input">
      <label class="col-sm-12 font-weight-bold">表示条件</label>
      <b-form-group class="col-sm-12">
        <div class="d-flex">
          <b-form-radio v-model="condition_setting" class="mr-3" :value="0"
            >設定しない(購入が必要)</b-form-radio
          >
          <b-form-radio v-model="condition_setting" class="mr-3" :value="1"
            >条件表示する</b-form-radio
          >
          <b-form-radio v-model="condition_setting" class="mx-3" :value="2"
            >全員表示する</b-form-radio
          >
        </div>
      </b-form-group>
    </div>
    <div v-if="condition_setting === 1">
      <div class="row mb-3 row-input pl-3">
        <label class="col-sm-12 font-weight-bold pl-0"
          >基本設定(どの変数を持つユーザーのみ表示させるか?を設定)</label
        >
        <div
          class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
        >
          <div class="custom-select-2 w-100 custome-multi-slag">
            <multiselect
              v-model="slag_type_1"
              tag-placeholder="購入付与スラグを追加してください"
              placeholder="購入付与スラグを追加してください"
              label="slag_name_en"
              track-by="slag_name_en"
              :options="listSlagOptions"
              :multiple="true"
              :taggable="false"
                            :showNoResults="false"
              :max-height="200"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="row mb-3 row-input pl-3">
        <label class="col-sm-12 font-weight-bold pl-0"
          >and1(基本設定とand1両方の変数を持つユーザーだけに表示)</label
        >
        <div
          class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
        >
          <div class="custom-select-2 w-100 custome-multi-slag">
            <multiselect
              v-model="slag_type_2"
              tag-placeholder="閲覧付与スラグを追加してください"
              placeholder="閲覧付与スラグを追加してください"
              label="slag_name_en"
              track-by="slag_name_en"
              :options="listSlagOptions"
              :multiple="true"
              :taggable="false"
                            :showNoResults="false"
              :max-height="200"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="row mb-3 row-input pl-3">
        <label class="col-sm-12 font-weight-bold pl-0"
          >and2(基本設定 + and1+2の3点の変数を持つユーザーだけに表示)</label
        >
        <div
          class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
        >
          <div class="custom-select-2 w-100 custome-multi-slag">
            <multiselect
              v-model="slag_type_3"
              tag-placeholder="投票付与スラグを追加してください"
              placeholder="投票付与スラグを追加してください"
              label="slag_name_en"
              track-by="slag_name_en"
              :options="listSlagOptions"
              :multiple="true"
              :taggable="false"
                            :showNoResults="false"
              :max-height="200"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="row mb-3 row-input pl-3">
        <label class="col-sm-12 font-weight-bold pl-0"
          >非表示(以下の変数を持つユーザーは非表示)</label
        >
        <div
          class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
        >
          <div class="custom-select-2 w-100 custome-multi-slag">
            <multiselect
              v-model="slag_type_4"
              tag-placeholder="投票付与スラグを追加してください"
              placeholder="投票付与スラグを追加してください"
              label="slag_name_en"
              track-by="slag_name_en"
              :options="listSlagOptions"
              :multiple="true"
              :taggable="false"
                            :showNoResults="false"
              :max-height="200"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3 row-input pr-3">
      <label class="col-sm-12 font-weight-bold pl-0"
        >表示条件を満たしていない場合のリダイレクト先URL:
      </label>
      <input
        class="form-control col-sm-12"
        type="text"
        v-model="redirect_not_met"
      />
    </div>
    <div class="mb-3 row-input pr-3">
      <label class="col-sm-12 font-weight-bold pl-0"
        >非表示条件に該当する場合のリダイレクト先URL:
      </label>
      <input
        class="form-control col-sm-12"
        type="text"
        v-model="redirect_met"
      />
    </div> -->
        <!-- <div class="row mb-3 row-input">
          <label class="col-sm-12 font-weight-bold">デザイン設定</label>
          <b-form-group class="col-sm-12">
            <div class="d-flex">
              <b-form-radio v-model="design_settings" class="mr-3" :value="0"
                >標準デザイン</b-form-radio
              >
              <b-form-radio v-model="design_settings" class="mx-3" :value="1"
                >note風デザイン</b-form-radio
              >
            </div>
          </b-form-group>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-12 font-weight-bold">目次ページの利用</label>
          <b-form-group class="col-sm-12">
            <div class="d-flex">
              <b-form-radio
                v-model="use_table_content_page"
                class="mr-3"
                :value="0"
                >利用しない</b-form-radio
              >
              <b-form-radio
                v-model="use_table_content_page"
                class="mx-3"
                :value="1"
                >利用する</b-form-radio
              >
            </div>
          </b-form-group>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-12 font-weight-bold"
            >ログインなしでのカバー表示可能
          </label>
          <b-form-group class="col-sm-12">
            <div class="d-flex">
              <b-form-radio
                v-model="possible_without_login"
                class="mr-3"
                :value="0"
                >利用しない</b-form-radio
              >
              <b-form-radio
                v-model="possible_without_login"
                class="mx-3"
                :value="1"
                >利用する</b-form-radio
              >
            </div>
          </b-form-group>
        </div> -->
        <!-- <div class="custom-button-update">
          <b-button
            type="button"
            class="btn btn-primary button-update"
            @click="updateChapterSettingID()"
            >登録・更新する
          </b-button>
        </div> -->
        <div>
          <b-modal
            id="modal-detail-slagCondition"
            modal-class="modalCustom"
            style="width: 720px"
            hide-header
          >
            <div class="d-block text-center">
              <!-- <h5>削除しますと復元できませんのでご注意ください</h5> -->
            </div>
            <!-- Content Condition -->
            <div class="px-3 customBackGround">
              <div class="p-2">
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >基本設定(以下のコンテンツを持っている人)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        class="col-sm-12 col-lg-12 p-0"
                        v-model="content_condition_1"
                        placeholder="コンテンツを選択してください"
                        label="text"
                        track-by="value"
                        :options="listContentOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :select-label="'入力して選択する'"
                        :deselect-label="'入力して削除する'"
                        :selected-label="'選択された'"
                        :loading="loadingContent"
                        @input="changeMutiSelectContentCondition()"
                        v-bind:class="{ 'error-input': errorContentIdRequired }"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >and1(基本設定とand1両方のコンテンツを持つユーザーだけに表示)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        class="col-sm-12 col-lg-12 p-0"
                        v-model="content_condition_2"
                        placeholder="コンテンツを選択してください"
                        label="text"
                        track-by="value"
                        :options="listContentOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :select-label="'入力して選択する'"
                        :deselect-label="'入力して削除する'"
                        :selected-label="'選択された'"
                        :loading="loadingContent"
                        @input="changeMutiSelectContentCondition()"
                        :disabled="disabledConditionContent2 === true"
                        v-bind:class="{ 'error-input': errorContentIdRequired }"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >and2(基本設定+and1+2の3点のコンテンツを持つユーザーだけに表示)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        class="col-sm-12 col-lg-12 p-0"
                        v-model="content_condition_3"
                        placeholder="コンテンツを選択してください"
                        label="text"
                        track-by="value"
                        :options="listContentOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :select-label="'入力して選択する'"
                        :deselect-label="'入力して削除する'"
                        :selected-label="'選択された'"
                        :loading="loadingContent"
                        :disabled="disabledConditionContent3 === true"
                        v-bind:class="{ 'error-input': errorContentIdRequired }"
                      ></multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Slag condition  --->
            <div class="px-3 mt-4 customBackGround">
              <div class="p-2">
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >基本設定(以下のスラグを持っている人)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        v-model="slag_condition_1"
                        tag-placeholder="スラグを選択してください"
                        placeholder="スラグを選択してください"
                        label="slag_name_en"
                        track-by="slag_name_en"
                        :options="listSlagOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :max-height="200"
                        select-label="入力して選択する"
                        selected-label="選択された"
                        deselect-label="入力して削除する"
                        @input="changeMutiSelectSlagCondition()"
                      >
                        <template v-slot:no-options> リストは空です </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >and1(基本設定とand1両方のスラグを持つユーザーだけに表示)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        v-model="slag_condition_2"
                        tag-placeholder="スラグを選択してください"
                        placeholder="スラグを選択してください"
                        label="slag_name_en"
                        track-by="slag_name_en"
                        :options="listSlagOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :max-height="200"
                        select-label="入力して選択する"
                        selected-label="選択された"
                        deselect-label="入力して削除する"
                        @input="changeMutiSelectSlagCondition()"
                        :disabled="disabledConditionSlag2 === true"
                      >
                        <template v-slot:no-options> リストは空です </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >and2(基本設定+and1+2の3点のスラグを持つユーザーだけに表示)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        v-model="slag_condition_3"
                        tag-placeholder="スラグを選択してください"
                        placeholder="スラグを選択してください"
                        label="slag_name_en"
                        track-by="slag_name_en"
                        :options="listSlagOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :max-height="200"
                        select-label="入力して選択する"
                        selected-label="選択された"
                        deselect-label="入力して削除する"
                        :disabled="disabledConditionSlag3 === true"
                      >
                        <template v-slot:no-options> リストは空です </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template #modal-footer="{}">
              <div class="">
                <b-button
                  v-on:click.prevent="updateConditionByChapter()"
                  :disabled="isLoading"
                  variant="info"
                  class="mr-4"
                  >アップデート
                </b-button>
                <b-button
                  variant="secondary"
                  @click="closeModalCondition()"
                  class="mx-2 back-btn"
                  >戻る</b-button
                >
              </div>
            </template>
          </b-modal>
        </div>
        <div>
          <b-modal
            id="modal-Condition-Page"
            modal-class="modalCustom"
            style="width: 720px"
            hide-header
          >
            <div class="d-block text-center">
              <!-- <h5>削除しますと復元できませんのでご注意ください</h5> -->
            </div>
            <!-- Content Condition -->
            <div class="px-3 customBackGround">
              <div class="p-2">
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >基本設定(以下のコンテンツを持っている人)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        class="col-sm-12 col-lg-12 p-0"
                        v-model="content_condition_1"
                        placeholder="コンテンツを選択してください"
                        label="text"
                        track-by="value"
                        :options="listContentOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :select-label="'入力して選択する'"
                        :deselect-label="'入力して削除する'"
                        :selected-label="'選択された'"
                        :loading="loadingContent"
                        @input="changeMutiSelectContentPageCondition()"
                        v-bind:class="{ 'error-input': errorContentIdRequired }"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >and1(基本設定とand1両方のコンテンツを持つユーザーだけに表示)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        class="col-sm-12 col-lg-12 p-0"
                        v-model="content_condition_2"
                        placeholder="コンテンツを選択してください"
                        label="text"
                        track-by="value"
                        :options="listContentOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :select-label="'入力して選択する'"
                        :deselect-label="'入力して削除する'"
                        :selected-label="'選択された'"
                        :loading="loadingContent"
                        @input="changeMutiSelectContentPageCondition()"
                        :disabled="disabledConditionContentPage2 === true"
                        v-bind:class="{ 'error-input': errorContentIdRequired }"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >and2(基本設定+and1+2の3点のコンテンツを持つユーザーだけに表示)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        class="col-sm-12 col-lg-12 p-0"
                        v-model="content_condition_3"
                        placeholder="コンテンツを選択してください"
                        label="text"
                        track-by="value"
                        :options="listContentOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :select-label="'入力して選択する'"
                        :deselect-label="'入力して削除する'"
                        :selected-label="'選択された'"
                        :loading="loadingContent"
                        :disabled="disabledConditionContentPage3 === true"
                        v-bind:class="{ 'error-input': errorContentIdRequired }"
                      ></multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Slag condition  --->
            <div class="px-3 mt-4 customBackGround">
              <div class="p-2">
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >基本設定(以下のスラグを持っている人)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        v-model="slag_condition_1"
                        tag-placeholder="スラグを選択してください"
                        placeholder="スラグを選択してください"
                        label="slag_name_en"
                        track-by="slag_name_en"
                        :options="listSlagOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :max-height="200"
                        select-label="入力して選択する"
                        selected-label="選択された"
                        deselect-label="入力して削除する"
                        @input="changeMutiSelectSlagPageCondition()"
                      >
                        <template v-slot:no-options> リストは空です </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >and1(基本設定とand1両方のスラグを持つユーザーだけに表示)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        v-model="slag_condition_2"
                        tag-placeholder="スラグを選択してください"
                        placeholder="スラグを選択してください"
                        label="slag_name_en"
                        track-by="slag_name_en"
                        :options="listSlagOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :max-height="200"
                        select-label="入力して選択する"
                        selected-label="選択された"
                        deselect-label="入力して削除する"
                        @input="changeMutiSelectSlagPageCondition()"
                        :disabled="disabledConditionSlagPage2 === true"
                      >
                        <template v-slot:no-options> リストは空です </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >and2(基本設定+and1+2の3点のスラグを持つユーザーだけに表示)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        v-model="slag_condition_3"
                        tag-placeholder="スラグを選択してください"
                        placeholder="スラグを選択してください"
                        label="slag_name_en"
                        track-by="slag_name_en"
                        :options="listSlagOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :max-height="200"
                        select-label="入力して選択する"
                        selected-label="選択された"
                        deselect-label="入力して削除する"
                        :disabled="disabledConditionSlagPage3 === true"
                      >
                        <template v-slot:no-options> リストは空です </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template #modal-footer="{}">
              <div class="">
                <b-button
                  v-on:click.prevent="updateConditionByPage()"
                  :disabled="isLoading"
                  variant="info"
                  class="mr-4"
                  >アップデート
                </b-button>
                <b-button
                  variant="secondary"
                  @click="closeModalConditionPage()"
                  class="mx-2 back-btn"
                  >戻る</b-button
                >
              </div>
            </template>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import { Constants } from "../../utils/constants";
export default {
  name: "TableOfContent",
  components: {
    draggable,
  },
  data() {
    return {
      fields: "",
      dataTableContent: [],
      listSlagOptions: [],
      listObjectPage: [],
      isActive: null,
      isChecked: [],
      pageChecked: [],
      objectSelected: [],
      elmClick: null,
      condition_setting: 0,
      detailed_setting: "",
      redirect_not_met: "",
      redirect_met: "",
      design_settings: 0,
      use_table_content_page: 0,
      possible_without_login: 0,
      page: 2,
      dataListPage: [],
      valueVisible: false,
      dataObject: {
        titlePage: null,
        page: [],
        id: null,
      },
      valueVisibleAddPage: false,
      listPageSelected: [],
      isSelected: null,
      dataModalAddPage: [],
      dataObjectSlected: [],
      slag_type_1: [],
      slag_type_2: [],
      slag_type_3: [],
      slag_type_4: [],
      dataOptionTitlePage: [],
      dataSelect: [],
      showAddPage: null,
      selectAdd: 1,
      content_condition_1: [],
      content_condition_2: [],
      content_condition_3: [],
      slag_condition_1: [],
      slag_condition_2: [],
      slag_condition_3: [],
      isShowFooter: true,
      isLoading: false,
      objectPageSelected: null,
      renderData: false,
      opDisable: [],
      listContentOptions: [],
      loadingContent: null,
      errorContentIdRequired: false,
      indexDataChapter: null,
      indexDataPage: null,
      disabledConditionContent2: false,
      disabledConditionContent3: false,
      disabledConditionSlag2: false,
      disabledConditionSlag3: false,
      disabledConditionContentPage2: false,
      disabledConditionContentPage3: false,
      disabledConditionSlagPage2: false,
      disabledConditionSlagPage3: false,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      indexChapterSelect: null,
    };
  },
  created() {
    const dataRequest = {
      page: 1,
      data: {
        shop_id: this.shop_id,
        limit: 50,
      },
    };
    this.getListPage(dataRequest);
    this.getListContents(dataRequest);
    this.getListChapters(1);
    const request = {
      shop_id: this.shop_id,
    };
    this.getALLlistSlag(request);
    const dataChapterRequest = {
      shop_id: this.shop_id,
      content_id: this.$route.params.id,
    };
    this.getListChapterSetting(dataChapterRequest);
    this.getChapterSetting(dataChapterRequest);
  },
  computed: {
    ...mapGetters([
      "listPage",
      "message",
      "success",
      "error",
      "listSlag",
      "listChapterSetting",
      "ChapterSettingByContent",
      "listChapter",
      "dataConditionChapter",
      "listContents",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listChapter() {
      for (let i = 0; i < this.listChapter.data.length; i++) {
        let dataOptionName = {
          value: null,
          text: null,
        };
        dataOptionName.value = this.listChapter.data[i].id;
        dataOptionName.text = this.listChapter.data[i].title;
        this.dataOptionTitlePage.push(dataOptionName);
      }
    },
    listPage() {
      this.dataListPage = this.listPage.data;
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name_en: item.slag_name_en,
        slag_url: item.url,
        slag_description: item.description,
      }));
    },
    listChapterSetting() {
      this.listChapterSetting.data.sort((a, b) =>
        a.position > b.position ? 1 : b.position > a.position ? -1 : 0
      );
      for (var i = 0; i < this.listChapterSetting.data.length; i++) {
        if (this.listChapterSetting.data[i].title) {
          this.$set(this.listChapterSetting.data[i], "is_page", 0);
        }
        this.disableOptionTitlepage(
          this.listChapterSetting.data[i].catalogue_id,
          i
        );
      }
      this.dataTableContent = this.listChapterSetting.data;
    },
    ChapterSettingByContent() {
      this.condition_setting = this.ChapterSettingByContent[0].is_view;
      if (this.ChapterSettingByContent[0].slag_id_1) {
        this.slag_type_1 = this.ChapterSettingByContent[0].slag_id_1.map(
          (item) => ({
            slag_id: item.slag_id,
            slag_name_en: item.slag_name_en,
          })
        );
      }
      if (this.ChapterSettingByContent[0].slag_id_2) {
        this.slag_type_2 = this.ChapterSettingByContent[0].slag_id_2.map(
          (item) => ({
            slag_id: item.slag_id,
            slag_name_en: item.slag_name_en,
          })
        );
      }
      if (this.ChapterSettingByContent[0].slag_id_3) {
        this.slag_type_3 = this.ChapterSettingByContent[0].slag_id_3.map(
          (item) => ({
            slag_id: item.slag_id,
            slag_name_en: item.slag_name_en,
          })
        );
      }
      if (this.ChapterSettingByContent[0].slag_id_4) {
        this.slag_type_4 = this.ChapterSettingByContent[0].slag_id_4.map(
          (item) => ({
            slag_id: item.slag_id,
            slag_name_en: item.slag_name_en,
          })
        );
      }
      this.redirect_not_met = this.ChapterSettingByContent[0].url_cancel;
      this.redirect_met = this.ChapterSettingByContent[0].url_redirect;
      this.design_settings = this.ChapterSettingByContent[0].design_settings;
      this.use_table_content_page =
        this.ChapterSettingByContent[0].Use_of_table;
      this.possible_without_login = this.ChapterSettingByContent[0].is_login;
    },
    listContents() {
      let listContents = [];
      listContents = this.listContents.data.map((item) => ({
        value: item.id,
        text: item.title,
      }));
      listContents.forEach((element) => {
        this.listContentOptions.push(element);
      });
      this.loadingContent = false;
    },
  },
  methods: {
    ...mapActions({
      getListPage: "getListPage",
      getALLlistSlag: "getALLlistSlag",
      getListChapterSetting: "getListChapterSetting",
      getChapterSetting: "getChapterSetting",
      getListChapter: "getListChapter",
      getConditionChapterAndPage: "getConditionChapterAndPage",
      getListContents: "getListContents",
    }),
    ClickContentChecked(index, event) {
      const elm = event.currentTarget;
      if (elm.closest(".objectItem")) {
        if (this.isChecked === index) {
          this.isChecked = null;
        } else {
          this.isChecked = index;
          this.isActive = null;
        }
      }
    },
    getListChapters(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          limit: this.limit,
        },
      };
      this.getListChapter(formData);
    },
    ClickPageChecked(object) {
      this.$set(object, "_checked", true);
      this.dataObject = {
        title: null,
        page: [],
        catalogue_id: null,
        is_page: 0,
        content_id_1: [],
        content_id_2: [],
        content_id_3: [],
        slag_id_1: [],
        slag_id_2: [],
        slag_id_3: [],
        position: this.dataTableContent.length + 1,
      };
      this.isChecked = null;
      if (this.objectSelected.find((item) => item.id === object.id)) {
        this.objectSelected.splice(this.objectSelected.indexOf(object), 1);
        this.$set(object, "_checked", false);
      } else {
        const page = {
          id: object.id,
          page_title: object.page_title,
          content_id_1: [],
          content_id_2: [],
          content_id_3: [],
          slag_id_1: [],
          slag_id_2: [],
          slag_id_3: [],
        };
        this.objectSelected.push(page);
        // this.dataModalAddPage["page"].push(page);
      }
      this.dataObject.page = this.objectSelected;
    },
    ClickAddPage(object) {
      // this.$set(object, "_checked", true);
      this.dataObject = {
        title: object.page_title,
        page: [],
        catalogue_id: null,
        is_page: 1,
        content_id_1: [],
        content_id_2: [],
        content_id_3: [],
        slag_id_1: [],
        slag_id_2: [],
        slag_id_3: [],
        position: this.dataTableContent.length + 1,
      };
      this.isChecked = null;
      this.dataListPage.forEach((element) => {
        if (element.id === object.id) {
          this.$set(element, "_checked", true);
          this.objectSelected = [];
          const page = {
            id: object.id,
            page_title: object.page_title,
            content_id_1: [],
            content_id_2: [],
            content_id_3: [],
            slag_id_1: [],
            slag_id_2: [],
            slag_id_3: [],
          };
          this.objectSelected.push(page);
        } else {
          this.$set(element, "_checked", false);
        }
      });
      // if (this.objectSelected.find((item) => item.id === object.id)) {
      //   this.objectSelected.splice(this.objectSelected.indexOf(object), 1);
      //   this.$set(object, "_checked", false);
      // } else {
      //   this.objectSelected = object;
      // }
      this.dataObject.page = this.objectSelected;
    },
    addPageinContent(index) {
      this.dataTableContent[index].page.push(this.pageChecked);
      const indexOfObject = this.objectSelected.page.indexOf(this.pageChecked);
      this.objectSelected.page.splice(indexOfObject, 1);
    },
    arrowUp(index, id) {
      const object = this.dataTableContent.find((item) => item.id === id);
      this.dataTableContent.splice(index, 1);
      this.dataTableContent.splice(index - 1, 0, object);
      this.isChecked = index - 1;
    },
    arrowDown(index, id) {
      const object = this.dataTableContent.find((item) => item.id === id);
      this.dataTableContent.splice(index, 1);
      this.dataTableContent.splice(index + 1, 0, object);
      this.isChecked = index + 1;
    },
    deletePage(index, objectPage, indexChapter) {
      this.dataTableContent[indexChapter].page.splice(index, 1);
    },
    async updateTableOfContent() {
      const listChpaters = [];
      this.dataTableContent.forEach((element) => {
        if (element.page.length > 0) {
          listChpaters.push(element);
        }
      });
      this.isLoading = true;
      const dataRequest = {
        content_id: this.$route.params.id,
        shop_id: this.shop_id,
        catalogues: listChpaters,
      };
      const dataReturn = await this.$store.dispatch(
        "updateChapterSettingByID",
        dataRequest
      );
      if (dataReturn.success) {
        this.isLoading = false;
        this.$toasted.success(dataReturn.message);
        this.$router.go(-1);
      } else {
        this.isLoading = false;
        this.$toasted.error(dataReturn.message);
        this.isLoading = false;
      }
    },
    gotoDashboard() {
      this.$router.go(-1);
    },
    deleteContent(index) {
      this.dataTableContent.splice(index, 1);
    },
    AddNewChapter() {
      this.isShowFooter = true;
      // this.valueVisible = true;
      this.selectAdd = 1;
      this.showAddPage = 0;
      this.$refs["modal-show-page"].show();
      this.dataObject = {
        titlePage: null,
        page: [],
        id: parseInt(this.dataTableContent.length) + 1,
      };
      this.objectSelected = [];
      this.dataListPage
        .filter((x) => x._checked == true)
        .forEach(function (item) {
          item._checked = false;
        });
    },
    closeModal() {
      this.$refs["modal-show-page"].hide();
      // this.dataTableContent.push(this.dataObject);
      // this.valueVisible = false;
    },
    OpenModalPage(items, ind) {
      this.selectAdd === 1;
      this.dataObjectSlected = [];
      this.dataModalAddPage = items;
      this.indexChapterSelect = ind;
      this.$refs["modal-add-page"].show();
      // this.dataListPage
      //   .filter((x) => x._selected == true)
      //   .forEach(function (item) {
      //     item._selected = false;
      //   });
    },
    addPageInChapter() {
      this.selectAdd = 1;
      this.dataObjectSlected.forEach((element) => {
        const getData = this.dataTableContent[
          this.indexChapterSelect
        ].page.filter((item) => item.id === element.id);
        if (getData.length < 1) {
          const page = {
            id: element.id,
            page_title: element.page_title,
            content_id_1: [],
            content_id_2: [],
            content_id_3: [],
            slag_id_1: [],
            slag_id_2: [],
            slag_id_3: [],
          };
          this.dataModalAddPage["page"].push(page);
        }
      });
      this.dataListPage
        .filter((x) => x._selected == true)
        .forEach(function (item) {
          item._selected = false;
        });
      this.$refs["modal-add-page"].hide();
      this.dataObjectSlected = [];
    },
    closeModalAddPage() {
      this.$refs["modal-add-page"].hide();
      this.dataObjectSlected = [];
      this.dataListPage
        .filter((x) => x._selected == true)
        .forEach(function (item) {
          item._selected = false;
        });
    },
    ClickPageAddChecked(object) {
      this.$set(object, "_selected", true);
      this.isChecked = null;
      if (this.dataObjectSlected.find((item) => item.id === object.id)) {
        this.dataObjectSlected.splice(
          this.dataObjectSlected.indexOf(object),
          1
        );
        this.$set(object, "_selected", false);
      } else {
        this.dataObjectSlected.push(object);
      }
    },
    async updateChapterSettingID() {
      if (
        this.redirect_not_met &&
        !this.redirect_not_met.match(Constants.REGEX_URL)
      ) {
        return this.$toasted.error(
          "表示条件を満たしていない場合のリダイレクト先URLの形式が正しくありません"
        );
      }
      if (this.redirect_met && !this.redirect_met.match(Constants.REGEX_URL)) {
        return this.$toasted.error(
          "非表示条件に該当する場合のリダイレクト先URLの形式が正しくありません"
        );
      }
      this.isLoading = true;
      const dataChapter = {
        content_id: this.$route.params.id,
        shop_id: this.shop_id,
        catalogues: this.dataTableContent,
      };
      await this.$store.dispatch("updateChapterSettingByID", dataChapter);
      const dataRequest = {
        id: this.$route.params.id,
        shop_id: this.shop_id,
        content_id: this.$route.params.id,
        is_view: this.condition_setting,
        slag_id_1: this.slag_type_1,
        slag_id_2: this.slag_type_2,
        slag_id_3: this.slag_type_3,
        slag_id_4: this.slag_type_4,
        url_cancel: this.redirect_not_met,
        url_redirect: this.redirect_met,
        design_settings: this.design_settings,
        Use_of_table: this.use_table_content_page,
        is_login: this.possible_without_login,
      };
      const dataReturn = await this.$store.dispatch(
        "updateChapterSetting",
        dataRequest
      );
      if (dataReturn.success) {
        this.$toasted.success(dataReturn.message);
        this.$router.go(-1);
      } else {
        this.$toasted.error(dataReturn.message);
        this.isLoading = false;
      }
    },
    disableOptionTitlepage(value, index) {
      this.opDisable = [];
      this.dataTableContent.map((item, ind) => {
        if (
          item.catalogue_id &&
          ind != index &&
          !this.opDisable.includes(item.catalogue_id)
        ) {
          this.opDisable.push(item.catalogue_id);
        }
      });
      if (!this.opDisable.includes(value)) {
        this.opDisable.push(value);
      }
    },
    showSlagConditionDetail(item, index) {
      this.indexDataChapter = index;
      const listOptionsContent = this.listContentOptions.reduce(
        (obj, cur) => ({ ...obj, [cur.value]: cur }),
        {}
      );
      const listOptionsSlag = this.listSlagOptions.reduce(
        (obj, cur) => ({ ...obj, [cur.slag_id]: cur }),
        {}
      );
      if (this.dataTableContent[this.indexDataChapter].content_id_1) {
        this.content_condition_1 = this.fomatObject(
          this.dataTableContent[this.indexDataChapter].content_id_1,
          listOptionsContent
        );
      } else {
        this.content_condition_1 = null;
      }
      if (this.dataTableContent[this.indexDataChapter].content_id_2) {
        this.content_condition_2 = this.fomatObject(
          this.dataTableContent[this.indexDataChapter].content_id_2,
          listOptionsContent
        );
      } else {
        this.content_condition_2 = null;
      }
      if (this.dataTableContent[this.indexDataChapter].content_id_3) {
        this.content_condition_3 = this.fomatObject(
          this.dataTableContent[this.indexDataChapter].content_id_3,
          listOptionsContent
        );
      } else {
        this.content_condition_3 = null;
      }
      if (this.dataTableContent[this.indexDataChapter].slag_id_1) {
        this.slag_condition_1 = this.fomatObject(
          this.dataTableContent[this.indexDataChapter].slag_id_1,
          listOptionsSlag
        );
      } else {
        this.slag_condition_1 = null;
      }
      if (this.dataTableContent[this.indexDataChapter].slag_id_2) {
        this.slag_condition_2 = this.fomatObject(
          this.dataTableContent[this.indexDataChapter].slag_id_2,
          listOptionsSlag
        );
      } else {
        this.slag_condition_2 = null;
      }
      if (this.dataTableContent[this.indexDataChapter].slag_id_3) {
        this.slag_condition_3 = this.fomatObject(
          this.dataTableContent[this.indexDataChapter].slag_id_3,
          listOptionsSlag
        );
      } else {
        this.slag_condition_3 = null;
      }
      this.$bvModal.show("modal-detail-slagCondition");
      this.changeMutiSelectContentCondition();
      this.changeMutiSelectSlagCondition();
    },
    showSlagConditionDetailPage(indexChapter, indexPage) {
      this.indexDataChapter = indexChapter;
      this.indexDataPage = indexPage;
      const listOptionsContent = this.listContentOptions.reduce(
        (obj, cur) => ({ ...obj, [cur.value]: cur }),
        {}
      );
      const listOptionsSlag = this.listSlagOptions.reduce(
        (obj, cur) => ({ ...obj, [cur.slag_id]: cur }),
        {}
      );
      if (this.dataTableContent[indexChapter].page[indexPage].content_id_1) {
        this.content_condition_1 = this.fomatObject(
          this.dataTableContent[indexChapter].page[indexPage].content_id_1,
          listOptionsContent
        );
      } else {
        this.content_condition_1 = null;
      }
      if (this.dataTableContent[indexChapter].page[indexPage].content_id_2) {
        this.content_condition_2 = this.fomatObject(
          this.dataTableContent[indexChapter].page[indexPage].content_id_2,
          listOptionsContent
        );
      } else {
        this.content_condition_2 = null;
      }
      if (this.dataTableContent[indexChapter].page[indexPage].content_id_3) {
        this.content_condition_3 = this.fomatObject(
          this.dataTableContent[indexChapter].page[indexPage].content_id_3,
          listOptionsContent
        );
      } else {
        this.content_condition_3 = null;
      }
      if (this.dataTableContent[indexChapter].page[indexPage].slag_id_1) {
        this.slag_condition_1 = this.fomatObject(
          this.dataTableContent[indexChapter].page[indexPage].slag_id_1,
          listOptionsSlag
        );
      } else {
        this.slag_condition_1 = null;
      }
      if (this.dataTableContent[indexChapter].page[indexPage].slag_id_2) {
        this.slag_condition_2 = this.fomatObject(
          this.dataTableContent[indexChapter].page[indexPage].slag_id_2,
          listOptionsSlag
        );
      } else {
        this.slag_condition_2 = null;
      }
      if (this.dataTableContent[indexChapter].page[indexPage].slag_id_3) {
        this.slag_condition_3 = this.fomatObject(
          this.dataTableContent[indexChapter].page[indexPage].slag_id_3,
          listOptionsSlag
        );
      } else {
        this.slag_condition_3 = null;
      }
      this.$bvModal.show("modal-Condition-Page");
      this.changeMutiSelectContentPageCondition();
      this.changeMutiSelectSlagPageCondition();
    },
    addPage() {
      this.objectSelected = [];
      this.dataListPage
        .filter((x) => x._checked == true)
        .forEach(function (item) {
          item._checked = false;
        });
      this.isShowFooter = false;
      this.selectAdd = 2;
      this.showAddPage = 1;
    },
    addChapter() {
      this.objectSelected = [];
      this.dataListPage
        .filter((x) => x._checked == true)
        .forEach(function (item) {
          item._checked = false;
        });
      this.isShowFooter = false;
      this.selectAdd = 2;
      this.showAddPage = 2;
    },
    backMenuAdd() {
      this.objectSelected = [];
      this.isShowFooter = true;
      this.selectAdd = 1;
      this.showAddPage = 0;
    },
    updateConditionChapter() {
      this.$refs["modal-show-page"].hide();
      this.dataTableContent.push(this.dataObject);
    },
    updateConditionByChapter() {
      this.dataTableContent[this.indexDataChapter].content_id_1 =
        this.converContentCondition(this.content_condition_1);
      this.dataTableContent[this.indexDataChapter].content_id_2 =
        this.converContentCondition(this.content_condition_2);
      this.dataTableContent[this.indexDataChapter].content_id_3 =
        this.converContentCondition(this.content_condition_3);
      this.dataTableContent[this.indexDataChapter].slag_id_1 =
        this.converSlagCondition(this.slag_condition_1);
      this.dataTableContent[this.indexDataChapter].slag_id_2 =
        this.converSlagCondition(this.slag_condition_2);
      this.dataTableContent[this.indexDataChapter].slag_id_3 =
        this.converSlagCondition(this.slag_condition_3);
      this.$bvModal.hide("modal-detail-slagCondition");
    },
    updateConditionByPage() {
      this.dataTableContent[this.indexDataChapter].page[
        this.indexDataPage
      ].content_id_1 = this.converContentCondition(this.content_condition_1);
      this.dataTableContent[this.indexDataChapter].page[
        this.indexDataPage
      ].content_id_2 = this.converContentCondition(this.content_condition_2);
      this.dataTableContent[this.indexDataChapter].page[
        this.indexDataPage
      ].content_id_3 = this.converContentCondition(this.content_condition_3);
      this.dataTableContent[this.indexDataChapter].page[
        this.indexDataPage
      ].slag_id_1 = this.converSlagCondition(this.slag_condition_1);
      this.dataTableContent[this.indexDataChapter].page[
        this.indexDataPage
      ].slag_id_2 = this.converSlagCondition(this.slag_condition_2);
      this.dataTableContent[this.indexDataChapter].page[
        this.indexDataPage
      ].slag_id_3 = this.converSlagCondition(this.slag_condition_3);
      this.$bvModal.hide("modal-Condition-Page");
    },
    closeModalCondition() {
      this.$bvModal.hide("modal-detail-slagCondition");
    },
    closeModalConditionPage() {
      this.$bvModal.hide("modal-Condition-Page");
    },
    converContentCondition(array) {
      if (array && array.length > 0) {
        const listContentsCondition = [];
        array.map((item) => {
          listContentsCondition.push(item.value);
        });
        return listContentsCondition;
      }
    },
    converSlagCondition(array) {
      if (array && array.length > 0) {
        const listContentsCondition = [];
        array.map((item) => {
          listContentsCondition.push(item.slag_id);
        });
        return listContentsCondition;
      }
    },
    fomatObject(array, object) {
      if (array && array.length > 0) {
        let ArrrayObject = [];
        Object.keys(object)
          .filter((key) => array.includes(parseInt(key)))
          .reduce((obj, key) => {
            ArrrayObject.push(object[key]);
          }, {});
        return ArrrayObject;
      }
    },
    returnSettingSale() {
      let id = this.$route.params.id ? this.$route.params.id : "";
      if (id) {
        this.$router.push({
          name: this.$route.params.shopId
            ? "Content Sale"
            : "Content Sale domain",
          params: { id: id },
        });
        return false;
      }
    },
    returnEditContent() {
      let id = this.$route.params.id ? this.$route.params.id : "";
      if (id) {
        this.$router.push({
          name: this.$route.params.shopId
            ? "Edit Content Change"
            : "Edit Content Change domain",
          params: { id: id },
        });
        return false;
      }
    },
    changeMutiSelectContentCondition() {
      if (!this.content_condition_1) {
        this.disabledConditionContent2 = true;
        this.content_condition_2 = null;
        this.content_condition_3 = null;
      } else {
        this.disabledConditionContent2 = false;
      }
      if (!this.content_condition_1 || !this.content_condition_2) {
        this.disabledConditionContent3 = true;
        this.content_condition_3 = null;
      } else {
        this.disabledConditionContent3 = false;
      }
    },
    changeMutiSelectSlagCondition() {
      if (!this.slag_condition_1) {
        this.disabledConditionSlag2 = true;
        this.slag_condition_2 = null;
        this.slag_condition_3 = null;
      } else {
        this.disabledConditionSlag2 = false;
      }
      if (!this.slag_condition_1 || !this.slag_condition_2) {
        this.disabledConditionSlag3 = true;
        this.slag_condition_3 = null;
      } else {
        this.disabledConditionSlag3 = false;
      }
    },
    changeMutiSelectContentPageCondition() {
      if (!this.content_condition_1) {
        this.disabledConditionContentPage2 = true;
        this.content_condition_2 = null;
        this.content_condition_3 = null;
      } else {
        this.disabledConditionContentPage2 = false;
      }
      if (!this.content_condition_1 || !this.content_condition_2) {
        this.disabledConditionContentPage3 = true;
        this.content_condition_3 = null;
      } else {
        this.disabledConditionContentPage3 = false;
      }
    },
    changeMutiSelectSlagPageCondition() {
      if (!this.slag_condition_1) {
        this.disabledConditionSlagPage2 = true;
        this.slag_condition_2 = null;
        this.slag_condition_3 = null;
      } else {
        this.disabledConditionSlagPage2 = false;
      }
      if (!this.slag_condition_1 || !this.slag_condition_2) {
        this.disabledConditionSlagPage3 = true;
        this.slag_condition_3 = null;
      } else {
        this.disabledConditionSlagPage3 = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-list-content {
  padding: 0px;
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0px !important;
  }
}
.custom-label {
  border: 1px solid #b5b5b5;
  padding: 20px;
  width: 100%;
  text-align: left;
  margin: 0px;
}
.customBackGround {
  border-radius: 25px;
  background-color: #d9d9d9;
}
>>> .modalCustom > .modal-dialog {
  max-width: 720px !important;
  margin: 1.75rem auto;
}
.customSelectAdd {
  border: 1px solid #d9d9d9;
  background-color: #d9d9d9;
}
.border-titlePage {
  text-align: center;
  padding: 5px 30px 5px 30px;
  border: 2px solid #828282;
  margin-top: revert;
  margin-bottom: revert;
  background-color: #fff;
}
.customListPage {
  .custom-button {
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: #595959;
    border-radius: 50%;
    border-color: #595959;
    display: block;
    margin-left: 1%;
    position: relative;
    .custom-span {
      position: absolute;
      top: -61%;
      right: 25.6%;
      font-size: 50px;
    }
  }
}
.textPage {
  width: 95%;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
