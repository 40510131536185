import { render, staticRenderFns } from "./TableOfContentsEdit.vue?vue&type=template&id=769c2126&scoped=true"
import script from "./TableOfContentsEdit.vue?vue&type=script&lang=js"
export * from "./TableOfContentsEdit.vue?vue&type=script&lang=js"
import style0 from "./TableOfContentsEdit.vue?vue&type=style&index=0&id=769c2126&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "769c2126",
  null
  
)

export default component.exports